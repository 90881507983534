import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { Auth0Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": true,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/",
    "logout": "/",
    "home": "/properties/",
    "callback": "/auth/callback"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": false,
  "defaultStrategy": "auth0",
  "token": {
    "global": true
  }
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // auth0
  $auth.registerStrategy('auth0', new Auth0Scheme($auth, {
  "domain": "id.sensyn.net",
  "clientId": "LRnuOBzaxzx1AMi6vz13XT4iUCVhc9av",
  "grantType": "authorization_code",
  "audience": "https://property-hub.sensyn.net/api",
  "scope": [
    "openid",
    "profile",
    "email",
    "openid",
    "profile",
    "email",
    "offline_access"
  ],
  "responseType": "code",
  "codeChallengeMethod": "S256",
  "logoutRedirectUri": "https://sensyn.net/sensyn-id/logout/?redirect_app=property-hub",
  "name": "auth0",
  "endpoints": {
    "authorization": "https://id.sensyn.net/authorize",
    "userInfo": "https://id.sensyn.net/userinfo",
    "token": "https://id.sensyn.net/oauth/token",
    "logout": "https://id.sensyn.net/v2/logout"
  }
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
